import React, { Component } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import './style.css';

export default class MobileMenu extends Component {

    state = {
        isMenuShow: false,
        isOpen: false,
    }

    menuHandler = () => {
        this.setState({
            isMenuShow: !this.state.isMenuShow
        })
    }

    setIsOpen = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    render() {

        const { isMenuShow, isOpen } = this.state;

        return (
            <div>
                <div className={`mobileMenu ${isMenuShow ? 'show' : ''}`}>
                    <div className="menu-close">
                         <div className="clox" onClick={this.menuHandler}><i className="fa fa-close"></i></div>
                    </div>
                    <ul className="responsivemenu">
                        <li><AnchorLink href="#demo">DEMOS</AnchorLink></li>
                        <li><AnchorLink href="#pages">PAGES</AnchorLink></li>
                        <li><a target="_blank" href="https://support.wpocean.com/">SUPPORT</a></li>
                    </ul>
                    
                </div>

                <div className="showmenu" onClick={this.menuHandler}>
                    <button type="button" className="navbar-toggler open-btn">
                            <span className="icon-bar first-angle"></span>
                            <span className="icon-bar middle-angle"></span>
                            <span className="icon-bar last-angle"></span>
                    </button>
                </div>       
            </div>
        )
    }
}
