import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import Homepage from '../HomePage'
import AllprojectsMenu from '../../components/allprojectsMenu'
import './App.css';


const App = () => {

  return (
    <div className="App" id="home">
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<Homepage/>} />
          <Route path='/home' element={<Homepage/>} />
        </Routes>
      </BrowserRouter>
      <AllprojectsMenu />
    </div>
  );
}

export default App;
